import React from 'react'
import SEO from '../components/seo/seo'
import Hero from '../components/hero/hero'


const LegalDisclaimerPage = () => (
  <>
    <SEO
      title="Legal Disclaimer"
      description="The Law Office of James G. Feleen, PLLC located in Claremont, NH specializing in Real Estate Law, Estate Planning, Probabte and General Practice Law."
      keywords={['law', 'lawyer', 'law office', 'attorney', 'James Feleen', 'Jim Feleen', 'Claremont', 'NH', 'New Hampshire', 'Claremont NH', 'real estate', 'probate administration', 'probate', 'personal injury', 'injury', 'wills', 'estate planning', 'title company', 'general practice', 'accident', 'insurance claim', 'negligence', 'medical malpractice', 'work injuries']} />
    <Hero />
    <div className="body-wrapper">
      <h1>Legal Disclaimer</h1>
      <h4>Please read this Disclaimer and website terms of use carefully. By using this website, you indicate your acceptance of the terms stated below.</h4>
      <p>
        This website is maintained solely for the purpose of providing general information about Law Office of James G. Feleen, PLLC. This website is not intended to constitute legal advice or the provision of legal services. By posting and/or maintaining this website and its contents, Law Office of James G. Feleen, PLLC does not intend to solicit legal business from clients located in states or jurisdictions where Law Office of James G. Feleen, PLLC or its individual attorneys are not licensed or authorized to practice law.
      </p>
      <p>
        Use of this website, including electronic mail, does not create an attorney-client relationship with Law Office of James G. Feleen, PLLC. Please do not send any confidential information to Law Office of James G. Feleen, PLLC until a formal attorney-client relationship has been established in writing. Email submitted to any of our attorneys, even if submitted in a good faith effort to retain us, and even if it is highly confidential, does not preclude us from representing another client directly adverse to you, even in a matter where that information could and will be used against you in accordance with applicable Rules of Professional Conduct.
      </p>
      <p>
        The content on this site is intended but not guaranteed to be accurate and current. Information on this site regarding past successes does not guarantee similar outcomes. Links to other websites do not imply any affiliation with, associated with, or sponsorship of those web sites.
      </p>
    </div>
  </>
)

export default LegalDisclaimerPage
